import React, { useState, useEffect } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { makeStyles, Paper, Tabs, Tab } from "@material-ui/core";

import TabPanel from "../../components/TabPanel";

import SchedulesForm from "../../components/SchedulesForm";
import CompaniesManager from "../../components/CompaniesManager";
import { CompanyForm } from "../../components/CompaniesManager";
import CompanyUserForm from "../../components/CompanyManager";
import PlansManager from "../../components/PlansManager";
import HelpsManager from "../../components/HelpsManager";
import Options from "../../components/Settings/Options";

import { i18n } from "../../translate/i18n.js";
import { toast } from "react-toastify";

import useCompanies from "../../hooks/useCompanies";
import useAuth from "../../hooks/useAuth.js";
import useSettings from "../../hooks/useSettings";

import OnlyForSuperUser from "../../components/OnlyForSuperUser";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  mainPaper: {
    ...theme.scrollbarStyles,
    overflowY: "scroll",
    flex: 1,
  },
  tab: {
    backgroundColor: theme.mode === 'light' ? "#f2f2f2" : "#7f7f7f",
    borderRadius: 4,
  },
  paper: {
    ...theme.scrollbarStyles,
    overflowY: "scroll",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxHeight: "100%",
  },
  control: {
    padding: theme.spacing(1),
  },
  textfield: {
    width: "100%",
  },
}));

const SettingsCustom = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [schedules, setSchedules] = useState([]);
  const [CompanyFormValue, setCompanyFormValue] = useState({});
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [settings, setSettings] = useState({});
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);

  const { getCurrentUserInfo } = useAuth();
  const { find, updateSchedules } = useCompanies();
  const { update } = useCompanies();
  const { getAll: getAllSettings } = useSettings();

  useEffect(() => {
    async function findData() {
      setLoading(true);
      try {
        const companyId = localStorage.getItem("companyId");
        const company = await find(companyId);
        console.log(company);
        const settingList = await getAllSettings();

        setCompany(company);
        setSettings(settingList);

        if (Array.isArray(settingList)) {
          const scheduleType = settingList.find(
            (d) => d.key === "scheduleType"
          );
          if (scheduleType) {
            setSchedulesEnabled(scheduleType.value === "company");
          }
        }

        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e);
      }
      setLoading(false);
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFindData = async () => {
    try {
      setLoading(true);
      const companyId = localStorage.getItem("companyId");
      const company = await find(companyId);
      const settingList = await getAllSettings();

      setCompany(company);
      setSettings(settingList);

      if (Array.isArray(settingList)) {
        const scheduleType = settingList.find((d) => d.key === "scheduleType");
        if (scheduleType) {
          setSchedulesEnabled(scheduleType.value === "company");
        }
      }

      const user = await getCurrentUserInfo();
      setCurrentUser(user);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      toast.error("Ocorreu um erro ao buscar dados. Por favor, tente novamente.");
    }
  }

  const handleTabChange = async (event, newValue) => {
    setLoading(true);
    setTab(newValue);
    handleFindData(newValue);
    setLoading(false);
  };


  const handleSubmitCompanyForm = async (data) => {
    console.log(data);
    setLoading(true);
    try {
      setCompany(data);
      await update({ id: company.id, ...data });
      toast.success("Empresa atualizada com sucesso.");
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  }
  const handleSubmitSchedules = async (data) => {
    setLoading(true);
    try {
      setSchedules(data);
      await updateSchedules({ id: company.id, schedules: data });
      toast.success("Horários atualizados com sucesso.");
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const isSuper = () => {
    return currentUser.super;
  };

  return (
    <MainContainer className={classes.root}>
      <MainHeader>
        <Title>{i18n.t("settings.title")}</Title>
      </MainHeader>
      <Paper className={classes.mainPaper} elevation={1}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          onChange={handleTabChange}
          className={classes.tab}
        >
          <Tab label="Opções" value={0} /> {/* Corrigido o valor para 0 */}
          {!isSuper() ? <Tab label="Dados da Empresa" value={1} /> : null}
          {isSuper() ? <Tab label="Empresas" value={2} /> : null}
          {isSuper() ? <Tab label="Planos" value={3} /> : null}
          {isSuper() ? <Tab label="Ajuda" value={4} /> : null}
        </Tabs>


        <Paper className={classes.paper} elevation={0}>
          <TabPanel className={classes.container} value={tab} index={0} name={0}>
            <Options
              settings={settings}
              scheduleTypeChanged={(value) =>
                setSchedulesEnabled(value === "company")
              }
            />
          </TabPanel>

          <TabPanel
            className={classes.container}
            value={tab}
            index={1}
            name={1}
          >
            <CompanyUserForm
              isSuperAdmin="false"
              loading={loading}
              onSubmit={handleSubmitCompanyForm}
              initialValues={company}
            />
          </TabPanel>
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                index={2}
                name={2}
              >
                <CompaniesManager />
              </TabPanel>
            )}
          />
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                index={3}
                name={3}
              >
                <PlansManager />
              </TabPanel>
            )}
          />
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                index={4}
                name={4}
              >
                <HelpsManager />
              </TabPanel>
            )}
          />
        </Paper>
      </Paper>
    </MainContainer>
  );
};

export default SettingsCustom;
