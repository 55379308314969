import React from 'react';
import Chip from '@material-ui/core/Chip';

function AnimatedEllipsis() {
  return (
    <span style={{ paddingLeft: 2 }}>
      <span style={{ animation: '1s blink step-start 0s infinite' }}>.</span>
      <span style={{ animation: '1s blink step-start 0.2s infinite' }}>.</span>
      <span style={{ animation: '1s blink step-start 0.4s infinite' }}>.</span>
    </span>
  );
}

function CustomChip({ contactPresence }) {
  const isProcessing = contactPresence.endsWith('...');

  const label = isProcessing ? contactPresence.slice(0, -3) : contactPresence;
  const animation = isProcessing ? <AnimatedEllipsis /> : null;

  return (
    <Chip
      size="small"
      label={<span>{label}{animation}</span>}
      style={{
        backgroundColor: isProcessing ? 'lightgrey' : undefined,
        color: isProcessing ? 'black' : undefined,
      }}
    />
  );
}

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = `
@keyframes blink {
  50% {
    opacity: 0;
  }
}
`;
document.head.appendChild(styleSheet);

export default CustomChip;