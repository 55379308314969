import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConnectionGroupModal from "../../components/ConnectionGroupModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip } from "@material-ui/core";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONNECTION_GROUPS") {
    const groups = action.payload;
    const newGroups = [];

    groups.forEach((group) => {
      const groupsIndex = state.findIndex((s) => s.id === group.id);
      if (groupsIndex !== -1) {
        state[groupsIndex] = group;
      } else {
        newGroups.push(group);
      }
    });

    return [...state, ...newGroups];
  }

  if (action.type === "UPDATE_GROUPS") {
    const group = action.payload;
    console.log(group)
    const groupsIndex = state.findIndex((s) => s.id === group.id);

    if (groupsIndex !== -1) {
      state[groupsIndex] = group;
      return [...state];
    } else {
      return [group, ...state];
    }
  }

  if (action.type === "DELETE_GROUP") {
    const groupId = action.payload;

    const groupsIndex = state.findIndex((s) => s.id === groupId);
    if (groupsIndex !== -1) {
      state.splice(groupsIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const ConnectionGroups = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedConnectionGroup, setSelectedConnectionGroup] = useState(null);
  const [deletingGroup, setdeletingGroup] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [groups, dispatch] = useReducer(reducer, []);
  const [ConnectionGroupModalOpen, setConnectionGroupModalOpen] = useState(false);

  const fetchGroups = useCallback(async () => {
    try {
      const { data } = await api.get("/connections-group/", {
        params: { searchParam, pageNumber },
      });
      
      dispatch({ type: "LOAD_CONNECTION_GROUPS", payload: data });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchGroups();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchGroups]);

  useEffect(() => {
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONNECTION_GROUPS", payload: data.tags });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONNECTION_GROUPS", payload: +data.tagId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleOpenConnectionGroupModal = () => {
    setSelectedConnectionGroup(null);
    setConnectionGroupModalOpen(true);
  };

  const handleCloseConnectionGroupModal = () => {
    setSelectedConnectionGroup(null);
    setConnectionGroupModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditGroup = (group) => {
    console.log(group);
    setSelectedConnectionGroup(group);
    setConnectionGroupModalOpen(true);
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await api.delete(`/connections-group/${groupId}`);
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setdeletingGroup(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchGroups();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={deletingGroup && `${i18n.t("tags.confirmationModal.deleteTitle")}`}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteGroup(deletingGroup.id)}
      >
        {i18n.t("tags.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ConnectionGroupModal
        open={ConnectionGroupModalOpen}
        onClose={handleCloseConnectionGroupModal}
        reload={fetchGroups}
        aria-labelledby="form-dialog-title"
        groupId={selectedConnectionGroup && selectedConnectionGroup.id}
        kanban={0}
      />
      <MainHeader>
        <Title>{i18n.t("connectionsGroup.title")} ({groups.length})</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenConnectionGroupModal}
          >
            {i18n.t("connectionsGroup.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("connectionsGroup.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("connectionsGroup.table.limit")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connectionsGroup.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {groups.map((group) => (
                <TableRow key={group.id}>
                  <TableCell align="center">
                    <Chip
                      variant="outlined"
                      style={{
                        backgroundColor: group.color,
                        textShadow: "1px 1px 1px #000",
                        color: "white",
                      }}
                      label={group.name}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">{group.limit}</TableCell>
                  <TableCell align="center">
                    <IconButton size="small" onClick={() => handleEditGroup(group)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setdeletingGroup(group);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ConnectionGroups;