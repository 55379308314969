import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import api from '../../services/api'; // Make sure to import your API correctly
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const CompanyPendings = () => {
  const classes = useStyles();
  const [pendings, setPendings] = useState([]);

  useEffect(() => {
    const fetchPendings = async () => {
      const { data } = await api.get("/checkSteps/");
      console.log(data);
      setPendings(data); // Update the state with the fetched data
    };
    fetchPendings();
  }, []);

  const renderPendingActions = (pending) => {
    return Object.keys(pending).map((key, index) => {
      if (pending[key]) { // If the value for the key is true, render an alert
        switch (key) {
          case 'NEEDS_DATA_UPDATE':
            return (
              <Alert
                key={index}
                style={{ marginBottom: '5px' }}
                severity="warning"
                variant="filled"
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    href="/settings"
                    className={classes.button}
                  >
                    Verificar
                  </Button>
                } >
                Você precisa atualizar seus dados fiscais e cadastrais para continuar usando a plataforma.
              </Alert>
            );
          case 'NEEDS_CONNECTION_GROUP':
            return (
              <Alert
                key={index}
                style={{ marginBottom: '5px' }}
                severity="warning"
                variant="filled"
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    href="/connection-groups"
                    className={classes.button}
                  >
                    Verificar
                  </Button>
                } >
                Você precisa definir pelo menos um grupo de conexão. Clique no botão ao lado para adicionar um.
              </Alert >
            );
          case 'NEEDS_DEFAULT_CONNECTION':
            return (
              <Alert
                key={index}
                style={{ marginBottom: '5px' }}
                severity="warning"
                variant="filled"
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    href="/connection-groups"
                    className={classes.button}
                  >
                    Verificar
                  </Button>
                } >
                Você precisa definir pelo menos uma conexão padrão, clique no botão ao lado para adicionar uma.
              </Alert>
            );
          case 'NEEDS_QUEUE':
            return (
              <><Alert
                key={index}
                style={{ marginBottom: '5px' }}
                severity="warning"
                variant="filled"
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    href="/connection-groups"
                  >
                    Verificar
                  </Button>}>
                <AlertTitle>Crie pelo menos uma Fila</AlertTitle>
                Você precisa criar pelo menos uma fila. Clique no botão ao lado para adicionar uma.
              </Alert>
                <div style="margin-bottom: 5px;"></div></>
            );
          default:
            return null; // Return null if the key does not match any case
        }
      }
      return null; // Return null if the value for the key is false
    });
  };

  // Iterate over the pendings array and return alerts for each pending action
  const pendingComponents = pendings.map((pending, index) => (
    <div key={index}>
      {renderPendingActions(pending)}
    </div>
  ));

  return (
    <div style={{ marginBottom: '20px' }}>
      {pendingComponents}
    </div>
  );
};

export default CompanyPendings;