import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import MaskedInput from "react-text-mask";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email")
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    name: "",
    number: "",
    email: ""
  };

  const [contact, setContact, setFieldValue] = useState(initialState);
  const [phoneType, setPhoneType] = useState("");

  useEffect(() => {
    const initialNumber = initialValues?.number || "";
    const newPhoneType = initialNumber.length <= 11 ? "mobile" : "fixed";
    setPhoneType(newPhoneType);
  }, [initialValues]);

  useEffect(() => {
    const initialNumber = initialValues?.number || "";
    const newNumber = removeCaractesEspeciaisTelefone(initialNumber);
    if (newNumber !== initialNumber) {
      setFieldValue("number", newNumber);
    }
  }, [initialValues, setFieldValue]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact(prevState => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          // no data.phone, remover os dois primeiros caracteres do telefone e aplicar a mascara
          if (!data.phone) {
            data.phone = data.number;
            var prePhone = data.number.slice(2);
            // Pega o terceiro caractere do telefone
            var phoneType = prePhone.slice(2, 3);
            let newNumber;
            if (phoneType >= 6) {
              setPhoneType("mobile");
              newNumber = prePhone.slice(0, 2) + 9 + prePhone.slice(2);
            } else {
              setPhoneType("fixed");
              newNumber = prePhone.slice(0, 2) + prePhone.slice(2);
            }
            data.number = newNumber;
          }
          setContact(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues, setPhoneType]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleSaveContact = async values => {
    try {
      if (contactId) {
        await api.put(`/contacts/${contactId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toast.error(i18n.t("contactModal.error"));
    }
  };

  const mobilePhoneMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  const fixedPhoneMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  const checkExists = async value => {
    if (value === "") {
      return;
    }

    const searchParam = { number: value };
    const { data } = await api.get("contacts", {
      params: { value }
    });
    console.log(data);
  };

  const removeCaractesEspeciaisTelefone = value => {
    // Remove todos os caracteres não numéricos
    let newValue = value.replace(/[^0-9]/g, "");

    // Verifica se o número é de telefone móvel ou fixo
    if (newValue.length >= 8 && newValue.length <= 11) {
      const data = checkExists(newValue);
      console.log(data);
      setPhoneType("mobile");
    } else {
      setPhoneType("fixed");
    }

    return newValue;
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId
            ? `${i18n.t("contactModal.title.edit")}`
            : `${i18n.t("contactModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={async (values, actions) => {
            const newNumber = removeCaractesEspeciaisTelefone(values.number);
            if (newNumber !== values.number) {
              actions.setFieldValue("number", newNumber);
            }
            setTimeout(async () => {
              try {
                if (contactId) {
                  await api.put(`/contacts/${contactId}`, values);
                } else {
                  const { data } = await api.post("/contacts", values);
                  if (onSave) {
                    onSave(data);
                  }
                }
                toast.success(i18n.t("contactModal.success"));
                handleClose();
                actions.setSubmitting(false);
              } catch (err) {
                const { data } = await api.get("contacts", {
                  params: { values }
                });
                if (data.contacts[0].id && data.contacts[0].number) {
                  var numerosemDDD = removeCaractesEspeciaisTelefone((data.contacts[0].number)).substr(2);
                  var phoneType = numerosemDDD.slice(2, 3);
                  var phoneTypePre = phoneType < 6 ? "fixed" : "mobile";
                  if (phoneTypePre == "mobile") {
                    var ddd = numerosemDDD.slice(0, 2);
                    data.contacts[0].number = ddd + 9 + numerosemDDD.slice(2);
                  } else {
                    var ddd = numerosemDDD.slice(0, 2);
                    data.contacts[0].number = ddd + numerosemDDD.slice(2);
                  }
                  setPhoneType(phoneType < 6 ? "fixed" : "mobile");
                  await api.put(`/contacts/${data.contacts[0].id}`, data.contacts[0]);
                  if (onSave) {
                    onSave(data);
                  }
                  handleClose();
                  toast.success(i18n.t("contactModal.success"));
                  actions.setSubmitting(false);
                }
                toast.error(i18n.t("contactModal.error"));
                actions.setSubmitting(false);
              }
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {i18n.t("contactModal.form.mainInfo")}
                </Typography>

                <Field
                  style={{ marginRight: 10 }}
                  as={TextField}
                  label={i18n.t("contactModal.form.number")}
                  name="number"
                  autoFocus
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="48999999999"
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      mask:
                        phoneType === "mobile"
                          ? mobilePhoneMask
                          : fixedPhoneMask,
                      guide: false,
                      onChange: event => {
                        event.target.value = removeCaractesEspeciaisTelefone(
                          event.target.value
                        );
                      }
                    }
                  }}
                  variant="outlined"
                  margin="dense"
                />

                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.name")}
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />

                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.email")}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    placeholder="Email address"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>
                <Typography
                  style={{ marginBottom: 8, marginTop: 12 }}
                  variant="subtitle1"
                >
                  {i18n.t("contactModal.form.extraInfo")}
                </Typography>

                <FieldArray name="extraInfo">
                  {({ push, remove }) => (
                    <>
                      {values.extraInfo &&
                        values.extraInfo.length > 0 &&
                        values.extraInfo.map((info, index) => (
                          <div
                            className={classes.extraAttr}
                            key={`${index}-info`}
                          >
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraName")}
                              name={`extraInfo[${index}].name`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraValue")}
                              name={`extraInfo[${index}].value`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <IconButton
                              size="small"
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        ))}
                      <div className={classes.extraAttr}>
                        <Button
                          style={{ flex: 1, marginTop: 8 }}
                          variant="outlined"
                          color="primary"
                          onClick={() => push({ name: "", value: "" })}
                        >
                          {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button
                  onClick={handleSaveContact}
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {contactId
                    ? `${i18n.t("contactModal.buttons.okEdit")}`
                    : `${i18n.t("contactModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ContactModal;
