import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReactGA from "react-ga4";

import App from "./App";
ReactGA.initialize('G-N5HS1GHDZ4');

// Call pageview after the app has been initialized
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <App />
  </React.Fragment>,
  document.getElementById("root")
);