import React, { useState, useEffect } from "react";
import { socketConnection } from "../../services/socket";

import { Avatar, CardHeader, Chip, CircularProgress } from "@material-ui/core";
import CustomChip from "../CustomChip";

import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
  const { user } = ticket;
  const [userName, setUserName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPresence, setContactPresence] = useState("");

  const getPresence = status => {
    switch (status) {
      case "avaliable":
        return "";
      case "composing":
        return "Digitando...";
      case "recording":
        return "Gravando Áudio...";
      default:
        return "";
    }
  };

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`ticket-presence-${ticket.id}-${companyId}`, data => {
      setContactPresence(getPresence(data.lastKnownPresence));
    });

    socket.on(`company-${companyId}-contact`, data => {
      if (data.action === "update") {
        if (data.contact.id === ticket.contactId) {
          setContactPresence("");
        }
      }
    });

    if (contact) {
      setContactName(contact.name);
      if (document.body.offsetWidth < 600) {
        if (contact.name.length > 10) {
          const truncadName = contact.name.substring(0, 10) + "...";
          setContactName(truncadName);
        }
      }
    }

    if (user && contact) {
      setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);

      if (document.body.offsetWidth < 600) {
        setUserName(`${user.name}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="ticket-info"
      style={{
        display: "flex",
        alignItems: "center", // This will vertically center the items
        justifyContent: "space-between" // This will space out the items
      }}
    >
      <div style={{ flex: 1, marginRight: "8px" }}>
        <CardHeader
          onClick={onClick}
          style={{ cursor: "pointer" }}
          titleTypographyProps={{ noWrap: true }}
          subheaderTypographyProps={{ noWrap: true }}
          avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
          title={`${contactName} #${ticket.id}`}
          subheader={ticket.user && `${userName}`}
        />
      </div>
      {contactPresence && contactPresence !== "offline" && (
        <div>
          <CustomChip contactPresence={contactPresence} />
        </div>
      )}
    </div>
  );
};

export default TicketInfo;
