import React, { useState, useEffect } from "react";
import qs from "query-string";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import MaskedInput from "react-text-mask";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useCallback } from "react";

import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Container,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  Link,
  Card,
  CardContent,
  Paper
} from "@material-ui/core";
import usePlans from "../../hooks/usePlans";
import Box from "@material-ui/core/Box";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
import logo from "../../assets/logologin.png";
import api from "../../services/api";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw",
    height: "100vh",
    background: "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    //backgroundColor: `rgba(${theme.palette.background.paper}, 0.8)`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  powered: {
    color: "white"
  },
  card: {
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      transform: "scale(1.05)"
    }
  },
  content: {
    textAlign: "center",
    padding: theme.spacing(3)
  },
  title: {
    fontWeight: "bold",
    paddingBottom: theme.spacing(2)
  },
  value: {
    fontSize: "18px",
    fontWeight: "bold"
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required")
});

const SignUp = () => {
  const steps = [
    "Dados de Contato",
    "Verificação",
    "Dados de Login",
    "Personalizar o Agentzap",
    "Finalizar"
  ];
  const [tryes, setTryes] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const isLastStep = activeStep === steps.length - 1;
  const [phoneType, setPhoneType] = useState("");
  const [sendCode, setSendCode] = useState(false);
  const [checkCode, setCheckCode] = useState(false);
  const [tokenCode, setTokenCode] = useState("");
  const [confirmCode, setConfirmCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [setFieldValue] = useState();

  const classes = useStyles();
  const history = useHistory();
  let companyId = null;

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = {
    phoneNumber: "",
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
    planId: ""
  };

  const [user] = useState(initialState);
  const dueDate = moment().add(3, "day").format();
  const handleSignUp = async values => {
    Object.assign(values, { recurrence: "MENSAL" });
    Object.assign(values, { dueDate: dueDate });
    Object.assign(values, { status: "t" });
    Object.assign(values, { campaignsEnabled: true });
    try {
      await openApi.post("/companies/cadastro", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      toastError(err);
    }
  };

  const sendConfirmationCode = async values => {
    setLoading(true);
    const { phoneNumber, email } = values;
    const data = {
      phoneNumber: phoneNumber,
      email: email
    };
    try {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/verify/create`,
        headers: {
          "Content-Type": "application/json"
        },
        data
      };
      axios
        .request(options)
        .then(function (response) {
          if (response.data.code) {
            setLoading(false);
            setActiveStep(activeStep + 1);
            toast.success(
              "Mensagem enviada com sucesso, por favor, digite o código recebido no seu e-mail, sms e/ou Whatsapp"
            );
          }
        })
        .catch(function (error) {
          setLoading(false);
          toastError(error);
        });
    } catch (err) {
      toastError(err);
    }
  };

  const checkConfirmationCode = async values => {
    setLoading(true);
    const { phoneNumber, email, code } = values;
    const data = {
      phoneNumber: phoneNumber,
      email: email,
      code: code
    };
    try {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/verify/check`,
        headers: {
          "Content-Type": "application/json"
        },
        data
      };
      axios
        .request(options)
        .then(function (response) {
          if (response.data.verify) {
            setLoading(false);
            setActiveStep(activeStep + 1);
            toast.success("Validado com Sucesso!");
          }
        })
        .catch(function (error) {
          setTryes(tryes + 1);
          setLoading(false);

          if (tryes >= 2) {
            setLoading(false);
            toast.error("Erro na Validação, por favor, tente novamente");
            setActiveStep(1);
          } else {
            setActiveStep(2);
            toast.error("Código Invalido, por favor, tente novamente");
          }
        });
    } catch (err) {
      toastError(err);
    }
  };

  const checkPassword = async values => {
    const { password, confirmPassword } = values;
    const data = {
      password: password,
      confirmPassword: confirmPassword
    };
    try {
      if (data.password === data.confirmPassword) {
        setActiveStep(activeStep + 1);
      } else {
        toast.error("As senhas devem ser iguais");
      }
    } catch (err) {
      toastError(err);
    }
  };

  const [plans, setPlans] = useState([]);
  const { list: listPlans } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const list = await listPlans();
      setPlans(list);
    }
    fetchData();
  }, []);

  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handlePlanClick = planId => {
    setPlans(
      plans.map(plan =>
        plan.id === planId
          ? { ...plan, selected: true }
          : { ...plan, selected: false }
      )
    );
    setSelectedPlanId(planId);
  };

  function _renderStepContent(
    step,
    setFieldValue,
    setActiveStep,
    values,
    errors
  ) {
    if (loading) {
      return (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      );
    }
    const gridSize = Math.max(1, Math.floor(12 / plans.length));
    switch (step) {
      case 1:
        return (
          <>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <Field
                as={TextField}
                name="email"
                error={values.email && Boolean(values.email)}
                variant="outlined"
                fullWidth
                id="email"
                label="E-mail de Login"
                placeholder="emaillogin@gmail.com"
                defaultValue="joaojkuligowski@gmail.com"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="phoneNumber"
                error={values.phoneNumber && Boolean(values.phoneNumber)}
                variant="outlined"
                fullWidth
                id="phoneNumber"
                label="Whatsapp"
                placeholder="48999999999"
                type="phone"
                // Permitir apenas numeros
                onInput={e => {
                  e.target.value = Math.max(0, parseInt(e.target.value) || 0)
                    .toString()
                    .slice(0, 11);
                }}
              />
            </Grid>
          </>
        );
      case 2:
        return (
          <Grid item xs={12}>
            <Field
              as={TextField}
              name="code"
              error={values.code && Boolean(values.code)}
              variant="outlined"
              fullWidth
              id="code"
              label="Código de verificação"
              placeholder="000000"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                }
              }}
            />
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Field
                as={TextField}
                name="name"
                error={values.name && Boolean(values.name)}
                variant="outlined"
                fullWidth
                id="name"
                label="Nome Completo ou da Empresa"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                as={TextField}
                name="password"
                error={values.password && Boolean(values.password)}
                variant="outlined"
                type="password"
                fullWidth
                id="password"
                label="Senha de Acesso"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                as={TextField}
                name="confirmPassword"
                error={
                  values.confirmPassword && Boolean(values.confirmPassword)
                }
                variant="outlined"
                type="password"
                fullWidth
                id="confirmPassword"
                label="Repita a Senha de Acesso"
              />
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Grid item xs={12}>
            <Typography variant="h5">
              Após o Teste Grátis de 7 dias, qual o plano mais se adapta a sua
              necessidade?
            </Typography>
            <Grid container spacing={3}>
              {plans.map((plan, key) =>
                plan.isPublic ? (
                  <Grid
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePlanClick(plan.id)}
                    item
                    xs={12}
                    sm={4}
                    key={key}
                  >
                    <Paper
                      className={`${classes.paper} ${plan.id === selectedPlanId ? classes.selected : ""
                        }`}
                      onClick={() => setFieldValue("planId", plan.id)}
                    >
                      <Typography variant="h6">{plan.name}</Typography>
                      <Typography variant="body2">
                        Atendentes: {plan.users}
                      </Typography>
                      <Typography variant="body2">
                        WhatsApp: {plan.connections}
                      </Typography>
                      <Typography variant="body2">
                        Filas: {plan.queues}
                      </Typography>
                      <Typography variant="body1">R$ {plan.value}</Typography>
                    </Paper>
                  </Grid>
                ) : null
              )}
            </Grid>
          </Grid>
        );
      default:
        return <div>Not Found</div>;
    }
  }

  function _handleSubmit(values, actions) {
    if (activeStep === 1) {
      const sendCode = () => {
        try {
          sendConfirmationCode(values);
        } catch (err) {
          toastError(err);
        }
      };

      sendCode();
    }

    if (activeStep === 2) {
      const checkCode = () => {
        try {
          checkConfirmationCode(values);
        } catch (err) {
          toastError(err);
        }
      };

      checkCode();
    }

    if (activeStep === 3) {
      const confirmPassword = () => {
        try {
          checkPassword(values);
        } catch (err) {
          toastError(err);
        }
      };

      confirmPassword();
    }

    if (activeStep === 4) {
      const submitForm = () => {
        try {
          _submitForm(values, actions);
        } catch (err) {
          toastError(err);
        }
      };

      submitForm();
    }

    actions.setTouched({});
    actions.setSubmitting(false);
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  async function _submitForm(values, actions) {
    try {

      const dueDate = moment().add(7, "day").format();

      const newValues = {
        name: values.name,
        phone: values.phoneNumber,
        email: values.email,
        password: values.password,
        planId: values.planId,
      };

      Object.assign(newValues, { recurrence: "MENSAL" });
      Object.assign(newValues, { dueDate: dueDate });
      Object.assign(newValues, { status: "t" });
      Object.assign(newValues, { campaignsEnabled: true });
      try {
        await openApi.post("/companies/cadastro", newValues);
        toast.success(i18n.t("signup.toasts.success"));
        history.push("/login");
      } catch (err) {
        toastError(err);
      }
    } catch (err) {
      actions.setSubmitting(false);

      toastError(err);
    }
  }

  return (
    <div className={classes.root}>
      <CookieConsent
        location="bottom"
        buttonText="Aceitar todos os Cookies"
        cookieName="acceptCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Este site usa cookies para aprimorar a sua experiência.{" "}
      </CookieConsent>

      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <img
              style={{ margin: "0 auto", height: "80px", width: "100%" }}
              src={logo}
              alt="Whats"
            />
          </div>
          <React.Fragment>
            <Typography component="h1" variant="h5">
              {i18n.t("signup.title")}
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order
                    confirmation, and will send you an update when your order
                    has shipped.
                  </Typography>
                </React.Fragment>
              ) : (
                <Formik
                  initialValues={{
                    ...user
                  }}
                  //validationSchema={currentValidationSchema}
                  onSubmit={_handleSubmit}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className={classes.form}>
                      {_renderStepContent(
                        activeStep,
                        setFieldValue,
                        setActiveStep,
                        values
                      )}

                      <div className={classes.buttons}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            {activeStep !== 1 && !loading && (
                              <Button
                                onClick={_handleBack}
                                className={classes.button}
                              >
                                VOLTAR
                              </Button>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {activeStep !== steps.length && !loading && (
                              <Button
                                disabled={isSubmitting}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                              >
                                {activeStep === 2
                                  ? "Verificar Código"
                                  : "PRÓXIMO"}
                              </Button>
                            )}
                            {isSubmitting && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </React.Fragment>
          </React.Fragment>
        </div>
        <Box mt={5}>{/* <Copyright /> */}</Box>
      </Container>
    </div>
  );
};

export default SignUp;
